<template>
  <v-container
    class="fill-height"
    fluid
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view
        :key="$route.fullPath"
        name="dataTable"
        style="width:100%"
        @toggle-right-drawer="rightDrawerToggle"
      />
    </transition>
    <router-view
      :key="$route.fullPath"
      :open="rightDrawerOpen"
      :data="rightDrawerData"
      name="rightDrawer"
      @close-drawer="() => {
        rightDrawerOpen = false
        rightDrawerData = null
      }"
    />
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      search: null,
      rightDrawerOpen: false,
      rightDrawerData: null,
    }),
    methods: {
      rightDrawerToggle (data, open) {
        this.rightDrawerOpen = open || !this.rightDrawerOpen
        this.rightDrawerData = data
      },
    },
  }
</script>

<style>
.v-data-table__wrapper{
    max-height:calc(100vh - 244px) !important;
}
</style>
